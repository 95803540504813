<template>
  <div class="change_bg">

    <Navbar></Navbar>
    <section>
      <div class="container">
        <div class="manage_link">
          <div class="m_link1">
            <router-link to="/">{{$store.state.language.lang[1]}}</router-link>
          </div>
          <div>
            <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M0.106862 0.191111C0.277469 -0.0260253 0.591796 -0.0637446 0.808932 0.106862L7.80893 5.60686C7.92958 5.70165 8.00002 5.84659 8.00002 6.00002C8.00002 6.15345 7.92958 6.29839 7.80893 6.39318L0.808932 11.8932C0.591796 12.0638 0.277469 12.0261 0.106862 11.8089C-0.0637446 11.5918 -0.0260253 11.2775 0.191111 11.1069L6.69073 6.00002L0.191111 0.89318C-0.0260253 0.722574 -0.0637446 0.408246 0.106862 0.191111Z"
                    fill="#89899F"/>
            </svg>
          </div>
          <div class="m_link2">
            <router-link to="/">
              {{$store.state.language.lang[146]}}
            </router-link>
          </div>
        </div>
        <div class="row">
          <div class="m_body_title">
            <h2>{{$store.state.language.lang[146]}}</h2>
          </div>
          <div class="connection_card" v-if="$store.state.contact[0]">
            <div class="row">
              <div class="col-md-6">
                <div class="connection_forms">
                  <form @submit.prevent="submit()">
                    <div class="connection_input">
                      <input type="text" v-model="full_name" :placeholder="$store.state.language.lang[95]">
                    </div>
                    <div class="connection_input">
                      <input type="text" v-model="phone" :placeholder="$store.state.language.lang[96]">
                    </div>
                    <div class="connection_input">
                      <input type="text" v-model="email" :placeholder="$store.state.language.lang[97]">
                    </div>
                    <div class="text_areas">
                      <textarea v-model="quiz"></textarea>
                    </div>
                    <button class="sent_connection">{{ $store.state.language.lang[101] }}</button>
                  </form>
                </div>
              </div>
              <div class="col-md-6">
                <div class="connection_card2">

                  <div class="col-md-12 connection_card2_ch">
                    <div class="contact_text">{{ $store.state.language.lang[59] }}:</div>
                    <div class="contact_description">{{$store.state.contact[0].first_phone_number}}</div>
                  </div>
                  <div class="col-md-12 connection_card2_ch">
                    <div class="contact_text">{{ $store.state.language.lang[50] }}:</div>
                    <div class="contact_description"> {{$store.state.contact[0].working_days}}</div>
                  </div>
                  <div class="col-md-12 connection_card2_ch">
                    <div class="contact_text">{{ $store.state.language.lang[49] }}:</div>
                    <div class="contact_description"> {{$store.state.contact[0].email}}</div>
                  </div>
                  <div class="col-md-12 connection_card2_ch">
                    <div class="contact_text">{{$store.state.language.lang[98]}}:</div>
                    <div class="contact_description">
                      {{$store.state.contact[0].order}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>
<script>
import Navbar from "../Header/Navbar";
import Footer from "../Header/Footer";

export default {
  components: {
    Footer,
    Navbar
  },
  data() {
    return {
      isActive_m: false,
      quiz:'',
      email:'',
      phone:'',
      full_name:''
    }
  },
  mounted() {
    this.$store.dispatch('contactFunck')
    this.quiz=this.$store.state.quizText
  },
  methods: {
    submit(){
      this.$http.post('/api/appeal/',{
        "full_name":this.full_name,
        "phone_number":this.phone,
        "email":this.email,
        "body":this.quiz
      },
      )
      .then(()=>{
        this.$toasted.show("So'rov yuborildi", {
          duration: 1000,
          'type': 'info'
        })
        this.$router.push('/')
      })
      .catch(()=>{
        this.$toasted.show("Email xato yozilgan", {
          duration: 1000,
          'type': 'error'
        })
      })

    }
  }
}
</script>
